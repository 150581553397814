import axios from "axios";
import { useAuthStore } from "@/stores/auth";

const axios_services = axios.create({

  //baseURL: "http://127.0.0.1:8080/",
  //baseURL: "https://dev.api.earthreceiver.geoville.com/",
  baseURL: 'https://dev.api.earthreceiver.geoville.com/'

});

axios_services.interceptors.request.use(
  function (config) {
    let key = "Bearer " + useAuthStore().accessToken;
    config.headers.Authorization = key;
    //config.headers["Content-Type"] = 'multipart/form-data';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axios_services;
